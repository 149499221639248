import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegisteredUserGuard} from "~app/common/guards/registered-user.guard";
import {MsalGuard} from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    data: {},
  },
  {
    path: 'home',
    loadChildren: () => import('~app/pages/home-page/home-page.module').then((m) => m.HomePageModule),
    data: {breadcrumb: 'Home'},
    canActivate: [MsalGuard]
  },
  {
    path: 'onboarding-start',
    loadChildren: () => import('~app/pages/onboarding-start-page/onboarding-start-page.module').then((m) => m.OnboardingStartPageModule),
    data: {breadcrumb: 'Onboarding-start'}
  },
  {
    path: 'onboarding',
    loadChildren: () => import('~app/pages/onboarding-page/onboarding-page.module').then((m) => m.OnboardingPageModule),
    data: {breadcrumb: 'Onboarding'}
  },
  {
    path: 'library',
    loadChildren: () => import('~app/pages/library-page/library-page.module').then(m => m.LibraryPageModule),
    data: {breadcrumb: 'Library'},
    canActivate: [/*RegisteredUserGuard, */MsalGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('~app/pages/notifications-page/notifications-page.module').then(m => m.NotificationsPageModule),
    data: {breadcrumb: 'Notifications'},
    canActivate: [/*RegisteredUserGuard, */MsalGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('~app/pages/profile-page/profile-page.module').then(m => m.ProfilePageModule),
    data: {breadcrumb: 'Profile'},
    canActivate: [/*RegisteredUserGuard, */MsalGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'//enabledNonBlocking
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
