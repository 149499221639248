import {Injectable} from '@angular/core';
import {AbstractControl, Validators, ValidatorFn} from '@angular/forms';
import {get} from 'lodash';

export const RegexPatterns = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/,
  phoneNumber: /\(\d{3}\)\d{3}-\d{4}/,
  digits: /^\d+$/,
  spaces: /^\s+$/,
  domain: /^[a-zA-Z0-9][a-zA-Z0-9-\.]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
  noSpaces: /^\S+$/,
  dbName: /^[a-zA-Z0-9_]+$/,
  name: /^[ A-Za-z\u00C0\u00FF()-._\\/0-9]+$/,
};

@Injectable()
export class CustomValidators {
  constructor() {
  }

  required = (control: AbstractControl): {} | null => {
    const baseRequired = Validators.required(control);
    const isRequired = baseRequired && baseRequired['required'];
    return isRequired
      ? {
        required: {
          message: '@@FIELD_REQUIRED',
          displayMessage: 'Please fill up the required field',
        },
      }
      : null;
  }

  phoneLength = (min: number): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const baseMinLength = Validators.minLength(min)(control);
      if (!baseMinLength) {
        return null;
      }
      const requiredLength = get(baseMinLength, 'maxlength.requiredLength');
      const actualLength = get(baseMinLength, 'maxlength.actualLength');
      return {
        minLength: {
          message: '@@PHONE_TOO_SHORT',
          displayMessage: `Phone should contain ${min} numbers`,
          requiredLength,
          actualLength,
        },
      };
    };
  }

  minLength = (min: number): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const baseMinLength = Validators.minLength(min)(control);
      if (!baseMinLength) {
        return null;
      }
      const requiredLength = get(baseMinLength, 'maxlength.requiredLength');
      const actualLength = get(baseMinLength, 'maxlength.actualLength');
      return {
        minLength: {
          message: '@@STRING_TOO_SHORT',
          displayMessage: `The length of the string is lower than expected (${min} symbols)`,
          requiredLength,
          actualLength,
        },
      };
    };
  }

  maxLength = (max: number): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const baseMaxLength = Validators.maxLength(max)(control);
      if (!baseMaxLength) {
        return null;
      }
      const requiredLength = get(baseMaxLength, 'maxlength.requiredLength');
      const actualLength = get(baseMaxLength, 'maxlength.actualLength');
      return {
        maxLength: {
          message: '@@STRING_TOO_LONG',
          displayMessage: 'The length of the string is higher than expected',
          requiredLength,
          actualLength,
        },
      };
    };
  }

  minValue = (min: number): ValidatorFn => {
    return (control: AbstractControl) => {
      if (control.value === undefined || control.value === null) {
        return null;
      }
      const minAmount = Validators.min(Number(min))(control);
      if (!minAmount) {
        return null;
      }
      return {
        minLength: {
          message: '@@NUMBER_TOO_SMALL',
          displayMessage: `Should be grater than ${min - 1}`
        },
      };
    };
  }

  maxValue = (max: number): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const maxAmount = Validators.max(Number(max))(control);
      if (!maxAmount) {
        return null;
      }
      return {
        minLength: {
          message: '@@NUMBER_TOO_BIG',
          displayMessage: `Should be smaller than ${max}`
        },
      };
    };
  }

  email = (control: AbstractControl): {} | null => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.email.test(control.value)
      ? null
      : {
        email: {
          message: '@@INVALID_EMAIL',
          displayMessage: 'Please ensure your email is valid',
        },
      };
  }

  phone = (control: AbstractControl): {} | null => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.phoneNumber.test(control.value)
      ? null
      : {
        phone: {
          message: '@@NOT_A_PHONE_NUMBER',
          displayMessage: 'Please enter a valid phone number (###)###-####',
        },
      };
  }

  digits = (control: AbstractControl): {} | null => {
    if (!control.value) {
      return null;
    }

    return RegexPatterns.digits.test(control.value)
      ? null
      : {
        digits: {
          message: '@@CONTAINS_NON_DIGITS',
          displayMessage: 'Please use only digits charters',
        },
      };
  }

  allSpaces = (control: AbstractControl): {} | null => {
    if (!control.value) {
      return null;
    }
    return !RegexPatterns.spaces.test(control.value)
      ? null
      : {
        email: {
          message: '@@INVALID_STRING',
          displayMessage: 'Please enter valid string',
        },
      };
  }

  currentTime = (minutes: number): ValidatorFn => {
    return (control: AbstractControl) => {
      if (control.value === undefined || control.value === null) {
        return null;
      }
      const isTimeLess = control.value < new Date(new Date().setMinutes(new Date().getMinutes() + minutes));
      if (!isTimeLess) {
        return null;
      }
      return {
        minLength: {
          message: '@@TIME_LESS_THEN_CURRENT',
          displayMessage: `Time should be later then current + ${minutes} min`
        },
      };
    };
  }

  domain = (control: AbstractControl): {} | null => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.domain.test(control.value)
      ? null
      : {
        email: {
          message: '@@INVALID_DOMAIN',
          displayMessage: 'Please ensure your domain is valid',
        },
      };
  }

  noSpaces = (control: AbstractControl): {} | null => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.noSpaces.test(control.value)
      ? null
      : {
        email: {
          message: '@@SPACES_NOT_ALLOWED',
          displayMessage: 'Spaces are not allowed',
        },
      };
  }

  dbName = (control: AbstractControl): {} | null => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.dbName.test(control.value)
      ? null
      : {
        email: {
          message: '@@DB_NAME_INCORRECT',
          displayMessage: 'Please ensure your DB name is valid',
        },
      };
  }

  name = (control: AbstractControl): {} | null => {
    if (!control.value) {
      return null;
    }
    return RegexPatterns.name.test(control.value)
      ? null
      : {
        email: {
          message: '@@NAME_INCORRECT',
          displayMessage: 'Please ensure your name is valid',
        },
      };
  }
}
