<app-sidebar></app-sidebar>
<app-main></app-main>
<!--<div class="page-wrapper" fxLayout="column" fxFlex="100">
  <mat-toolbar class="app-header">
    <span fxFlex="100" fxLayout="row" fxLayoutAlign="space-between">
      <a [href]="'/'">
      <img src="/assets/images/youturn-logo-primary.svg" class='youturn-logo' alt="Youturn-logo"></a>
      <button mat-flat-button
              class="custom-btn small-custom-btn">Contact Us</button>
    </span>
  </mat-toolbar>-->

  <!--<app-sidebar *ngIf="isUserRegistered"></app-sidebar>
  <app-main [showBreadcrumbs]="isUserRegistered"></app-main>
</div>-->
