import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @HostBinding('class.collapsed')
  /*get collapsed() {
    return this.isCollapsed;
  }*/
  isCollapsed = false;
  constructor() { }

  ngOnInit(): void {
  }

  setCollapsedState() {
    this.isCollapsed = !this.isCollapsed;
  }
}
