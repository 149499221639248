import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import {environment} from "~environments/environment";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_Participant_Onboarding_email",
    editProfile: "b2c_1_edit_profile_v2"
  },
  authorities: {
    signUpSignIn: {
      authority: "https://youturnhealth.b2clogin.com/youturnhealth.onmicrosoft.com/B2C_1_Participant_Onboarding_email",
    },
    editProfile: {
      authority: "https://youturnhealth.b2clogin.com/youturnhealth.onmicrosoft.com/b2c_1_edit_profile_v2"
    }
  },
  authorityDomain: "youturnhealth.b2clogin.com"
};


export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId,
    authority: environment.authority,
    //knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: environment.redirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}

/*export const protectedResources = {
  todoListApi: {
    endpoint: "http://localhost:5000/api/todolist",
    scopes: ["https://youturnhealth.onmicrosoft.com/api/tasks.read"],
  },
}*/
export const loginRequest = {
  scopes: []
};
