<ul>
  <li>
    <app-logo [isCollapsed]="isCollapsed"></app-logo>
  </li>
  <li *ngFor="let item of buttons">
    <a [routerLink]="item.routerLink" class="nav-list__link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
      <button mat-button aria-label="icon-button with hover origin icon" [ngClass]="{ 'collapsed': isCollapsed }" >
        <mat-icon class="material-icons-outlined">{{ item.icon }}</mat-icon>
        <span *ngIf="!isCollapsed">{{ item.label }}</span>
      </button>
    </a>
  </li>
</ul>
