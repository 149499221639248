import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss']
})
export class NavListComponent implements OnInit {

  @Input() isCollapsed = false;
  buttons = [
    {
      routerLink: '/home',
      isLinkActive: 'true',
      label: 'Home',
      icon: 'person'
    },
    {
      routerLink: '/library',
      isLinkActive: 'false',
      label: 'Library',
      icon: 'video_library'
    },
    {
      routerLink: '/notifications',
      isLinkActive: 'false',
      label: 'Notitications',
      icon: 'notifications'
    },
    {
      routerLink: '/profile',
      isLinkActive: 'false',
      label: 'Profile',
      icon: 'account_circle'
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
