<!-- <a routerLink="/">
  <button mat-button class="logo__button" aria-label="icon-button with trip origin icon">
    <img src="assets/images/youturn-logo.svg" alt="logo" />
  </button>
</a> -->

<a routerLink="/">
  <button mat-button class="logo__button" aria-label="icon-button with trip origin icon">
    <svg class="logo__svg" [ngClass]="{ 'collapsed': isCollapsed }" width="100" height="32" viewBox="0 0 100 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip)">
        <path class="logo__collapsed" d="M9.1962 7.69242C9.77117 6.73097 10.3214 5.81593 10.8684 4.89924C11.7333 3.44878 12.5949 1.99832 13.4582 0.547854C13.5323 0.423528 13.5752 0.277653 13.7778 0.279311C15.2968 0.285942 16.8141 0.282626 18.3973 0.282626C17.7927 1.26231 17.221 2.19392 16.6444 3.12222C14.936 5.87561 13.2276 8.629 11.5142 11.3791C11.3775 11.5995 11.3181 11.8184 11.3198 12.077C11.3297 13.1395 11.3148 14.2037 11.3297 15.2663C11.333 15.5332 11.2654 15.6161 10.9919 15.6128C9.79753 15.5978 8.60475 15.5978 7.41034 15.6128C7.1418 15.6161 7.06437 15.5398 7.06931 15.2696C7.08414 14.2071 7.07096 13.1428 7.07919 12.0803C7.07919 11.8383 7.02977 11.6327 6.89797 11.4222C4.66894 7.84326 2.44979 4.25938 0.230646 0.675494C0.161452 0.562773 0.0972009 0.448393 0 0.284284C1.57334 0.284284 3.09066 0.287599 4.60798 0.279311C4.83533 0.279311 4.87158 0.450051 4.94901 0.579349C6.26699 2.78239 7.57838 4.9871 8.89306 7.1918C8.98367 7.34265 9.07593 7.4935 9.19785 7.69242H9.1962Z" fill="white"/>
        <path class="logo__collapsed" d="M9.20955 20.5786C8.05467 20.5836 7.11561 19.657 7.10243 18.5016C7.08925 17.3445 8.01349 16.4096 9.1766 16.4063C10.3463 16.4013 11.2837 17.3163 11.2887 18.4684C11.2936 19.6188 10.3529 20.572 9.20955 20.5786Z" fill="#E1E2E5"/>

        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M68.5942 16.7364C68.3075 17.1541 67.9747 17.5304 67.6304 17.9001C65.973 19.6837 62.7638 19.6174 60.9664 17.7973C59.968 16.7861 59.5248 15.5478 59.398 14.1736C59.2398 12.4529 59.3535 10.7257 59.3304 9.00333C59.3156 7.81644 59.3387 6.62955 59.3205 5.44265C59.3156 5.14262 59.4079 5.08128 59.6879 5.08625C60.8395 5.10283 61.9927 5.10615 63.146 5.08625C63.4639 5.07962 63.5265 5.17577 63.5249 5.47912C63.5117 7.88109 63.52 10.2831 63.515 12.685C63.515 13.1575 63.571 13.6166 63.7209 14.0659C64.2992 15.8064 66.5974 15.9075 67.6255 15.0058C68.324 14.3924 68.7474 13.6945 68.7227 12.685C68.665 10.2847 68.7095 7.88109 68.6963 5.47912C68.6963 5.17908 68.754 5.07962 69.0752 5.08625C70.2416 5.1078 71.4081 5.10117 72.5745 5.08957C72.8166 5.08791 72.9007 5.13433 72.9007 5.40287C72.8908 9.80564 72.8924 14.2101 72.9007 18.6129C72.9007 18.8731 72.8232 18.9361 72.5761 18.9328C71.3965 18.9212 70.2153 18.9179 69.0357 18.9328C68.7507 18.9361 68.693 18.8333 68.6996 18.5747C68.7161 17.9746 68.7046 17.3746 68.7046 16.7745C68.6667 16.7629 68.6304 16.7513 68.5925 16.7397L68.5942 16.7364Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M90.6159 7.31364C90.9322 7.00697 91.1744 6.66549 91.4511 6.35054C93.7905 3.70489 98.191 4.63153 99.5089 8.0397C99.8368 8.88512 99.9768 9.77031 99.9851 10.6655C100.006 13.2895 99.9883 15.9136 99.9999 18.5377C99.9999 18.8427 99.934 18.9356 99.6193 18.9289C98.4661 18.9091 97.3128 18.9107 96.1613 18.9289C95.8647 18.9339 95.7972 18.8394 95.7988 18.556C95.8104 16.1673 95.8038 13.7786 95.8071 11.3882C95.8071 11.0136 95.784 10.6439 95.6934 10.2809C95.471 9.39568 94.9504 8.78565 94.0492 8.5685C93.0261 8.32151 92.12 8.54695 91.3819 9.32606C90.863 9.87309 90.5862 10.4881 90.601 11.2904C90.6455 13.6924 90.6093 16.096 90.6258 18.4996C90.6274 18.8444 90.5368 18.9389 90.1958 18.9323C89.0705 18.9107 87.9453 18.9157 86.8201 18.9306C86.5334 18.9339 86.4198 18.8908 86.4214 18.5576C86.4346 14.193 86.4346 9.82999 86.4214 5.46534C86.4214 5.15038 86.5038 5.0791 86.8069 5.08408C87.9601 5.10231 89.1134 5.10231 90.265 5.08408C90.5599 5.0791 90.634 5.16862 90.6241 5.45374C90.6027 6.0737 90.6175 6.69533 90.6175 7.31696L90.6159 7.31364Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M41.6018 16.686C41.2344 17.0689 40.928 17.4634 40.5837 17.8331C38.8258 19.7129 35.7549 19.5537 34.035 17.974C33.0102 17.0324 32.5094 15.8571 32.3084 14.5094C32.1881 13.6988 32.2161 12.8849 32.2145 12.0727C32.2112 9.8779 32.2211 7.68149 32.2046 5.48673C32.203 5.15354 32.2969 5.07729 32.6148 5.08226C33.7532 5.10215 34.8933 5.10215 36.0317 5.08226C36.3381 5.07729 36.4172 5.15188 36.4156 5.46518C36.4007 7.93677 36.4106 10.4084 36.4057 12.8799C36.4057 13.2993 36.4683 13.7038 36.6067 14.0967C37.1965 15.7776 39.4453 15.9267 40.526 14.9835C41.213 14.3835 41.6397 13.6856 41.6199 12.6976C41.5721 10.2956 41.6117 7.89367 41.5969 5.49005C41.5952 5.16183 41.6825 5.07729 42.0038 5.08226C43.129 5.10215 44.2542 5.09718 45.3795 5.08392C45.6464 5.0806 45.7617 5.12204 45.76 5.43534C45.7501 9.82651 45.7501 14.2177 45.76 18.6072C45.76 18.8641 45.6908 18.9321 45.4388 18.9288C44.2592 18.9155 43.0779 18.9172 41.8983 18.9288C41.6644 18.9304 41.5952 18.8658 41.6002 18.6287C41.615 17.9823 41.6051 17.3341 41.6051 16.686H41.6018Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M57.0107 15.1565C57.0107 16.2174 57.0058 17.2518 57.014 18.2845C57.0157 18.495 56.9003 18.5713 56.7422 18.6442C56.2512 18.8697 55.7323 18.999 55.2018 19.0421C53.9975 19.1366 52.7899 19.183 51.6548 18.6509C50.5147 18.1154 50.0765 17.1159 49.9282 15.9339C49.7915 14.8482 49.8689 13.7574 49.859 12.67C49.8459 11.4002 49.8492 10.1304 49.8623 8.86065C49.8656 8.60205 49.7915 8.52911 49.5394 8.54072C49.1012 8.56061 48.6613 8.53575 48.2231 8.55066C48.0139 8.55729 47.9348 8.50093 47.9381 8.27549C47.9496 7.30907 47.9513 6.34265 47.9381 5.37788C47.9348 5.12923 48.0254 5.07784 48.2478 5.08613C48.686 5.10105 49.1259 5.07784 49.5641 5.09608C49.8014 5.10602 49.864 5.02646 49.8607 4.79438C49.8492 3.84288 49.8656 2.88972 49.8492 1.93821C49.8442 1.6531 49.9183 1.56358 50.2099 1.56855C51.3615 1.58513 52.5148 1.58347 53.6664 1.56855C53.9448 1.56524 54.0453 1.62823 54.0387 1.92827C54.0189 2.83833 54.0552 3.75005 54.0206 4.66011C54.0057 5.04635 54.1359 5.111 54.4786 5.09939C55.1903 5.07287 55.9053 5.09939 56.6186 5.08779C56.8493 5.08447 56.9629 5.11763 56.958 5.39612C56.9399 6.34762 56.9448 7.30078 56.958 8.25228C56.9613 8.49099 56.8855 8.55729 56.6549 8.55232C55.8871 8.53906 55.1178 8.55729 54.3501 8.54238C54.0996 8.5374 54.0255 8.60205 54.0271 8.86231C54.0387 10.6294 54.0288 12.3948 54.0354 14.1619C54.0387 15.0239 54.4324 15.4085 55.3006 15.4317C55.8773 15.4482 56.4259 15.2758 57.0107 15.1598V15.1565Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M79.8137 12.0265C79.8137 14.2229 79.8055 16.4176 79.822 18.614C79.8236 18.934 79.733 18.9986 79.4348 18.9937C78.2552 18.9754 77.074 18.9787 75.8944 18.9937C75.6341 18.997 75.5484 18.9373 75.5501 18.6571C75.56 14.2378 75.56 9.81844 75.5501 5.40075C75.5501 5.13552 75.6292 5.08248 75.8746 5.08579C77.0822 5.09739 78.2898 5.10237 79.4974 5.08413C79.7956 5.07916 79.8203 5.19851 79.8187 5.44385C79.8104 7.64026 79.8137 9.83502 79.8137 12.0314V12.0265Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M55.538 27.9338C55.538 29.0793 55.5215 30.2264 55.5463 31.3718C55.5545 31.7597 55.3321 31.7017 55.1014 31.7083C54.8691 31.715 54.683 31.7315 54.6912 31.3818C54.7143 30.485 54.6912 29.5865 54.7044 28.6897C54.7077 28.4361 54.6352 28.3582 54.3798 28.3598C53.2826 28.3731 52.1838 28.3748 51.0849 28.3598C50.8048 28.3565 50.7422 28.4543 50.7455 28.7162C50.7587 29.5998 50.7356 30.485 50.7587 31.3669C50.7669 31.6967 50.6154 31.7282 50.3633 31.7067C50.1458 31.6884 49.9037 31.7863 49.9053 31.3851C49.9201 29.066 49.9201 26.7453 49.9053 24.4245C49.902 24.0234 50.1458 24.1212 50.3633 24.1029C50.6154 24.0814 50.7669 24.1129 50.7587 24.4428C50.7356 25.3677 50.762 26.2927 50.7439 27.2177C50.7389 27.5045 50.8197 27.5957 51.1096 27.5907C52.1805 27.5741 53.2513 27.5708 54.3205 27.5907C54.6434 27.5973 54.7077 27.4879 54.7027 27.1895C54.6846 26.2778 54.7143 25.3661 54.6879 24.456C54.6764 24.0947 54.8543 24.0947 55.1113 24.0996C55.3568 24.1046 55.5512 24.0781 55.543 24.4494C55.5182 25.6081 55.5347 26.7685 55.5347 27.9289L55.538 27.9338Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M94.3757 27.9169C94.3757 26.7715 94.3872 25.626 94.3674 24.4822C94.3608 24.1507 94.4877 24.0993 94.776 24.0977C95.066 24.0977 95.1895 24.1557 95.1829 24.4839C95.1615 25.394 95.1846 26.3057 95.1698 27.2157C95.1648 27.4876 95.2126 27.602 95.5207 27.5953C96.6031 27.5754 97.6871 27.5771 98.7711 27.5953C99.0891 27.6003 99.1649 27.4843 99.1599 27.1859C99.1435 26.2891 99.1665 25.3923 99.1468 24.4955C99.1402 24.1839 99.2357 24.0877 99.5422 24.0993C99.8123 24.1093 99.9639 24.1258 99.9606 24.4723C99.9458 26.7632 99.9458 29.0524 99.9606 31.3433C99.9623 31.6881 99.8123 31.7064 99.5422 31.7163C99.2357 31.7263 99.1402 31.6334 99.1468 31.3201C99.1665 30.4515 99.1451 29.5812 99.1583 28.7126C99.1632 28.4457 99.0907 28.3562 98.814 28.3612C97.7167 28.3761 96.6195 28.3794 95.5223 28.3612C95.2142 28.3562 95.1648 28.4722 95.1681 28.7441C95.1829 29.6127 95.1566 30.483 95.1813 31.3516C95.1912 31.708 95.0149 31.7097 94.7546 31.7147C94.4861 31.7213 94.3625 31.6633 94.3674 31.3549C94.3839 30.2095 94.374 29.064 94.374 27.9202L94.3757 27.9169Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M84.5435 7.08807C84.5336 8.23684 83.6143 9.1469 82.4726 9.14027C81.2963 9.13364 80.382 8.18877 80.3952 6.99856C80.4083 5.87963 81.3721 4.88834 82.4776 4.9331C83.8713 4.9878 84.6094 6.08518 84.5419 7.08807H84.5435Z" fill="#E1E2E5"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M59.8642 27.9075C59.8642 26.762 59.8725 25.6166 59.8576 24.4728C59.8543 24.1794 59.9482 24.0981 60.2316 24.1014C61.4376 24.1147 62.6452 24.1213 63.8511 24.0998C64.2086 24.0932 64.1954 24.2738 64.2103 24.5308C64.2284 24.8341 64.1081 24.8971 63.833 24.8922C62.9137 24.8772 61.9944 24.9038 61.0768 24.8772C60.7242 24.8673 60.6385 24.9833 60.6534 25.3198C60.6797 25.9249 60.6748 26.5332 60.6534 27.14C60.6435 27.4218 60.7127 27.5229 61.0109 27.5129C61.7094 27.4897 62.4096 27.5196 63.1081 27.4997C63.4014 27.4914 63.4409 27.6174 63.4392 27.866C63.4392 28.1097 63.4063 28.2423 63.1098 28.234C62.398 28.2125 61.683 28.239 60.9713 28.2208C60.711 28.2141 60.6468 28.2987 60.6534 28.549C60.6698 29.2253 60.6748 29.9016 60.6534 30.5763C60.6435 30.8813 60.7572 30.9393 61.0323 30.9344C61.9928 30.9194 62.9516 30.9427 63.9121 30.9211C64.2366 30.9128 64.2926 31.0289 64.2959 31.3223C64.2992 31.6372 64.1971 31.7168 63.8956 31.7135C62.6896 31.6969 61.482 31.6969 60.2761 31.7135C59.9598 31.7184 59.8494 31.6389 59.856 31.3024C59.8758 30.1718 59.8642 29.0396 59.8642 27.9091V27.9075Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M88.5021 28.2902C88.5021 29.3114 88.4873 30.3325 88.5104 31.3536C88.5186 31.715 88.3324 31.7034 88.0804 31.7117C87.8052 31.7216 87.6965 31.6503 87.6982 31.347C87.7097 29.3462 87.6916 27.3454 87.7146 25.3429C87.7196 24.9451 87.5927 24.8539 87.2303 24.8771C86.7789 24.9053 86.3242 24.8622 85.8727 24.8904C85.5498 24.9103 85.5531 24.7329 85.5614 24.5091C85.568 24.3052 85.5136 24.098 85.8513 24.0996C87.3472 24.1129 88.8415 24.1129 90.3374 24.0996C90.6751 24.0963 90.6241 24.2986 90.6274 24.5025C90.6306 24.7146 90.6636 24.9103 90.3259 24.8904C89.8333 24.8605 89.3374 24.8986 88.8448 24.8771C88.573 24.8655 88.4922 24.9517 88.4972 25.2236C88.5104 26.2447 88.5021 27.2658 88.5021 28.2869V28.2902Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M78.7507 27.87C78.7507 26.7378 78.7655 25.6057 78.7424 24.4751C78.7358 24.137 78.8693 24.0922 79.151 24.1005C79.4195 24.1088 79.6041 24.0939 79.5991 24.4784C79.5777 26.4925 79.5975 28.5082 79.581 30.5223C79.5777 30.8538 79.6716 30.9417 79.9945 30.9334C80.8858 30.9119 81.7787 30.94 82.6684 30.9185C82.983 30.9102 82.988 31.066 82.9863 31.2964C82.9863 31.5335 82.9995 31.7175 82.6568 31.7125C81.4641 31.6943 80.2696 31.6959 79.0769 31.7125C78.7853 31.7158 78.7441 31.5965 78.7457 31.3478C78.7556 30.1891 78.7507 29.0287 78.7507 27.87Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M29.9264 10.0094C28.9626 6.11723 24.18 3.94403 20.5935 5.23369C15.9871 6.89137 14.7136 11.5279 16.2639 15.0123C17.5341 17.8701 19.9576 19.0189 22.5589 19.2012C24.7237 19.1714 26.3498 18.6426 27.7155 17.4955C30.0813 15.5079 30.6513 12.9269 29.9281 10.0078L29.9264 10.0094ZM22.9296 15.5179C21.0268 15.5179 19.6264 14.0442 19.6379 12.055C19.6495 10.0244 21.0894 8.47776 22.9576 8.49931C24.5721 8.5192 26.2196 9.79892 26.2015 12.0815C26.185 14.0741 24.8192 15.5195 22.9296 15.5195V15.5179Z" fill="white"/>
        <path [@fade]="isCollapsed ? 'out' : 'in'" d="M74.9729 31.3639C73.9811 29.0663 72.9993 26.7638 72.0289 24.4547C71.902 24.1513 71.7126 24.1116 71.4276 24.095C71.1129 24.0768 71.0322 24.2624 70.9383 24.4829C70.2677 26.0544 69.5956 27.6258 68.9251 29.199C68.5774 30.0145 68.2331 30.8334 67.8855 31.6556C68.6367 31.7882 68.7208 31.7385 68.9745 31.0904C69.103 30.7588 69.2562 30.4372 69.388 30.1057C69.4522 29.9466 69.5396 29.8769 69.7224 29.8786C70.8888 29.8869 72.0536 29.8869 73.22 29.8786C73.4161 29.8786 73.5215 29.9482 73.5923 30.1239C73.7522 30.5168 73.9482 30.8964 74.0816 31.2976C74.197 31.6457 74.3914 31.7451 74.7423 31.717C75.0223 31.6938 75.1014 31.6606 74.9746 31.3655L74.9729 31.3639ZM72.8806 29.189C71.9482 29.1791 71.0173 29.1824 70.0849 29.1874C69.8971 29.1874 69.8262 29.136 69.9168 28.9486C69.9465 28.8873 69.9663 28.821 69.9926 28.758C70.4753 27.6093 70.958 26.4621 71.4852 25.2073C71.9037 26.1953 72.2711 27.0639 72.6385 27.9325C72.7768 28.2607 72.9119 28.5906 73.052 28.9188C73.1294 29.1012 73.1129 29.1923 72.8773 29.1907L72.8806 29.189Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip">
          <rect width="100" height="31.441" fill="white" transform="translate(0 0.279297)"/>
        </clipPath>
      </defs>
    </svg>
  </button>
</a>
