import { Component, Input, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', animate('.3s ease-in-out')),
      transition('out => in', animate('.3s ease-in-out'))
    ])
  ]
})
export class LogoComponent implements OnInit {
  @Input() isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }
}
