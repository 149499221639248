import { Inject, Injectable } from '@angular/core';
import { PromptValue } from '@azure/msal-common';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular';
import { RedirectRequest, SilentRequest } from '@azure/msal-browser';

import {BehaviorSubject, catchError, Observable} from 'rxjs';
import {environment} from "~environments/environment";
import {Roles} from "~app/common/constants/auth.type";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  role$ = new BehaviorSubject<Roles>('')
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
  ) {}

  get bearer() {
    const activeAccount = this.activeAccount;
    if (activeAccount) {
      // @ts-ignore
      const key = `${activeAccount.homeAccountId}-${activeAccount.environment}-accesstoken-${environment.clientId}-${activeAccount.tenantId}-${environment.resourceScopes[0].toLowerCase()}--`;
      // @ts-ignore
      let token = JSON.parse(localStorage.getItem(key))?.secret;
      // @ts-ignore
      if (activeAccount.idTokenClaims?.exp * 1000 < Date.now()) {
        this.refreshTokenSilent(); //NOTE: the token will not be updated in the return, so possible for the call to error
      }
      return token;
    }
  }

  get activeAccount(): unknown {
    return this.msalService.instance.getAllAccounts()[0];
  }

  updateRole() {
    // @ts-ignore
    this.role$.next(this.activeAccount?.idTokenClaims?.roles[0] as Roles);
  }

  login(redirectRequest?: RedirectRequest) {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...redirectRequest
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect(redirectRequest);
    }
  }

  logout(redirectUri?: string) {
    this.msalService.logoutRedirect({ postLogoutRedirectUri: redirectUri });
  }

  refreshTokenSilent() {
    const silentRequest: SilentRequest = {
      authority: environment.authority,
      scopes: [...environment.resourceScopes]
    };
    this.msalService
      .acquireTokenSilent(silentRequest)
      .pipe(
        catchError(() => {
          // fall back to interactive login if silent refresh token fails
          const redirectRequest: RedirectRequest = {
            authority: environment.authority,
            scopes: [...environment.resourceScopes],
            prompt: PromptValue.LOGIN
          };
          return this.msalService.loginRedirect(redirectRequest);
        })
      )
      .subscribe();
  }
}
