import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from "~app/common/services/auth/auth.service";


@Injectable()
export class PrimaryInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authorizedRequest: HttpRequest<any> = request.clone({
      headers: request.headers.set(
        'Authorization',
        'Bearer ' + this.authService.bearer
      )
    });

    return next.handle(authorizedRequest);
  }
}
