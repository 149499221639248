import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidebarComponent} from "~app/features/sidebar/sidebar.component";
import {RouterModule} from "@angular/router";
import {MaterialModule} from "~app/shared/material/material.module";
import {SharedModule} from "~app/shared/shared.module";
import {PrimaryInterceptor} from "~app/common/interceptors/primary.interceptor";
import {MainComponent} from "~app/features/main/main.component";
import {CustomValidators} from "~app/common/error-handling/error.validators";
import {FlexModule} from "@angular/flex-layout";

import {MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';

// Import the Azure AD B2C configuration
import {msalConfig/*, protectedResources*/} from './auth-config';

// Add the essential Angular materials.
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    MainComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    MaterialModule,
    SharedModule,
    FlexModule,

    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatTableModule,
    HttpClientModule,
    MsalModule.forRoot(new PublicClientApplication(msalConfig),
      {
        // The routing guard configuration.
        interactionType: InteractionType.Redirect,
        authRequest: {
          //scopes: protectedResources.todoListApi.scopes
        }
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(/*[
          [protectedResources.todoListApi.endpoint, protectedResources.todoListApi.scopes]
        ]*/)
      }),
  ],
  providers: [
    CustomValidators,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PrimaryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
