// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'ENV_VAR_ENVIRONMENT_NAME',
  clientId: 'ENV_VAR_CLIENT_ID',
  authority: 'ENV_VAR_AUTHORITY',
  redirectUri: 'ENV_VAR_REDIRECT_URI',
  apiUrl: 'ENV_VAR_API_URL',
  graphUrl: 'ENV_VAR_GRAPH_URL',
  resourceScopes: ['ENV_VAR_RESOURCE_SCOPES'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
