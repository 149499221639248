import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MaterialModule} from "~app/shared/material/material.module";
import {LogoComponent} from "~app/shared/logo/logo.component";
import {NavListComponent} from './nav-list/nav-list.component';
import {LogoutComponent} from './buttons/logout/logout.component';
import {BreadcrumbsComponent} from "~app/shared/breadcrumbs/breadcrumbs.component";

@NgModule({
  declarations: [
    LogoComponent,
    NavListComponent,
    LogoutComponent,
    BreadcrumbsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
  ],
  exports: [
    LogoComponent,
    NavListComponent,
    LogoutComponent,
    BreadcrumbsComponent,
  ]
})
export class SharedModule {
}
